import WebUse from "../components/static/WebUse"

const WebsitesVisitors = () => {
  return (
    <div className="password-reset-page">
     <WebUse />
    </div>
  )
}

export default WebsitesVisitors