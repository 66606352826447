import { DashboardContainer, DashboardRow } from "./components/DashboardComponents";

const PayPlans = () => {
  return (
    <DashboardContainer>
      <DashboardRow>
        <h2>Below are your subscriptions.</h2>
      </DashboardRow>
      
    </DashboardContainer>
  )
}

export default PayPlans;