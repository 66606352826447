import { Adverts } from '../components/dynamic/Adverts.component';

const AdvertsPage = () => {
  return (
    <>
      <Adverts limit={0} />
    </>
  )
}

export default AdvertsPage 