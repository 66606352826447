
const TermsPage = () => {
     return (
     <div className="static-page">
          <h2>Terms and Conditions</h2>
          <h3>Clickrwanda Terms of Use</h3>
          <p>Clickrwanda is provided to you by HUZA 250 Ltd Ltd, also referred to below as <b>Clickrwanda</b> </p>
          <p>This policy is effective <b> 1st January 2024.</b></p>
          <h4>Using Clickrwanda</h4>
          <p>As a condition of your use of Clickrwanda, you agree that you will not:</p>
          <ul>
               <li>violate any applicable laws including but not limited to consumer protection, data protection and intellectual property laws (including their regulations and guidelines);</li>
               <li>violate any applicable posting rules</li>
               <li>post listings that do not show clear, truthful, verifiable, complete and unambiguous information regarding your contact details, the goods, price, delivery and any additional charge;</li>
               <li>post any threatening, abusive, defamatory, pornographic, obscene, unconstitutional or indecent material;</li>
               <li>use the Services in any manner that could impair any of our websites or applications in any way or interfere with any party’s use or enjoyment of any such site or application;</li>
               <li>post any material that is harmful to, harms or could harm minors in any way;</li>
               <li>be false or misleading or employ false or misleading advertising practices;</li>
               <li>infringe any third-party right;</li>
               <li>distribute or contain spam, chain letters, or pyramid schemes;</li>
               <li>distribute viruses or any other technologies that may harm Clickrwanda or the interests or property of Clickrwanda users;</li>
               <li>impose an unreasonable load on our infrastructure or interfere with the proper working of Clickrwanda;</li>
               <li>copy, modify, or distribute any other person&apos;s content without their consent;</li>
               <li>use any robot spider, scraper or other automated means to access Clickrwanda and collect content for any purpose without our express written permission;</li>
               <li>harvest or otherwise collect information about others, including email addresses, without their consent or otherwise violate the privacy of another person;</li>
               <li>use the information available in Clickrwanda to contact the users or the advertisers for any purpose other than for the use of the Services;</li>
               <li>bypass measures used to prevent or restrict access to Clickrwanda.</li>
          </ul>

          <p>You are solely responsible for all information that you submit to Clickrwanda and any consequences that may result from your post. We reserve the right for any or no reason, at our discretion to refuse or delete or take-down content that (or any part thereof) we believe is inappropriate or breaching the above terms. We may, at our discretion, remove ads, put ads on hold or make minor changes thereto, when they do not comply with the Posting Rules. We also reserve the right at our discretion to restrict a user&apos;s usage of the site either temporarily or permanently, or refuse a user&apos;s registration.</p>
          <h4>Abusing Clickrwanda</h4>
          <p>Clickrwanda and the Clickrwanda community work together to keep the site working properly and the community safe. Please report problems, offensive content and policy breaches to us using the reporting system.</p>
          <p>Without limiting other remedies, we may issue warnings, limit or terminate our service, remove hosted content exercise our discretion when ads do not comply with our policies, and take technical and legal steps to keep users off Clickrwanda if we think that they are creating problems or acting inconsistently with the letter or spirit of our policies. However, whether we decide to take any of these steps, remove hosted content, exercise our discretion when ads do not comply with our policies, or keep a user off Clickrwanda or not, we do not accept any liability for monitoring Clickrwanda or for unauthorized or unlawful content on Clickrwanda or use of Clickrwanda by users. You also recognise and accept that Clickrwanda is not under any obligation to monitor any data or content which is submitted to or available on the site.</p>
          <h4>Fees and Services</h4>
          <p>Using Clickrwanda is generally free, but we sometimes charge a fee for certain services. If the service you use incurs a fee, you will be able to review and accept that charge and any additional terms and conditions applicable, including forms of payment, to the service at the time of purchase. This will be clearly disclosed at the time you post your ad. Our fees are quoted in Rwandan Francs, and we may change them from time to time. We will notify you of changes to our fee policy by posting such changes on the site. We may choose to temporarily change our fees for promotional events or new services; these changes are effective when we announce the promotional event or new service.</p>
          <p>Our fees are non-refundable, and you are responsible for paying them when they are due. If you don&apos;t, we may limit your ability to use the services. If your payment method fails or your account is past due, we may collect fees owed using other permissible collection mechanisms.</p>
          <h4>Content</h4>
          <p>Clickrwanda contains content from us, you, and other users. Clickrwanda is protected by copyright laws and international treaties. Content displayed on or via Clickrwanda is protected as a collective work and/or compilation, pursuant to copyrights laws and international conventions. You agree not to copy, distribute or modify content from Clickrwanda without our express written consent. You may not disassemble or decompile, reverse engineer or otherwise attempt to discover any source code contained in Clickrwanda. Without limiting the foregoing, you agree not to reproduce, copy, sell, resell, or exploit for any purposes any aspect of Clickrwanda (other than your own content).</p>
          <p>When providing us with content or causing content to be posted using our Services, you grant us a non-exclusive, worldwide, perpetual, irrevocable, royalty-free, sub-licensable (through multiple tiers) licence to exercise any and all copyright, publicity, trademark, and database rights and other intellectual property rights you have in the content, in any media known now or developed in the future. This means that we are entitled to host, cache, route, transmit, store, copy, modify, reproduce, distribute, syndicate, publish, translate, use, publicly perform, publicly display, reformat, modify, edit, excerpt, analyze, and create algorithms and derivative works from you content, or otherwise use such content. Further, to the fullest extent permitted under applicable law, you waive your moral rights and agree not to assert such rights or any other intellectual property or publicity rights against us, our sub-licensees, or our assignees.</p>
          <p>You represent and warrant that none of the following infringe the licence (or any part thereof) granted to us in the preceding paragraph: your provision of content to us, your causing content to be posted using the Services, and use of any such content (including of works derived from it) by us, our users, or others in contract with us that is done in connection with the Services and in compliance with the Clickrwanda Terms of Use.</p>
          <h4>Infringement</h4>
          <p>Do not post content that infringes the rights of third parties. This includes, but is not limited to, content that infringes on intellectual property rights such as copyright, design and trademark (e.g. offering counterfeit items for sale). A large number of varied products are offered on Clickrwanda by private individuals in Rwanda. Entitled parties, in particular the owners of copyright, trademark rights or other rights owned by third parties can report any offers which many infringe on their rights and submit a request for this offer to be removed. If a legal representative of the entitled party reports this to us in the correct manner, products infringing on the intellectual property rights will be removed by Clickrwanda.</p>
          <h4>Liability</h4>
          <p>Clickrwanda does not act as a supplier as contemplated in the consumer protection act, or otherwise, and is not the party marketing the goods/services. Clickrwanda only provides the platform.</p>
          <p>As explained above, we do not actively monitor data or content. We are not involved in the actual transactions between users. Clickrwanda does not operate as a payment intermediary, payment processor, payment facilitator, money transmitter, money services business, or any type of business akin thereto for the purposes of, or in connection with, any transaction on the site. Clickrwanda also does not fulfil any of the functions (whether as a whole or in part), of a payment intermediary, payment processor, payment facilitator, money transmitter, money services business, or any type of business akin thereto, whether in a direct or indirect manner.</p>
          <p>As most of the content on clickrwanda comes from other users, we do not guarantee or give any warranty or make any representation as to the accuracy and content of postings or user communications or the quality, safety, or legality of what is offered, or with regard to the legal capacity of the users that may conduct or complete any transaction. You agree not to hold us responsible for things other users post or do.</p>
          <p>In no event do we accept liability of any description for the posting of any unlawful, threatening, abusive, defamatory, obscene or indecent information, or material of any kind which violates or infringes upon the rights of any other person, including without limitation any transmissions constituting or encouraging conduct that would constitute a criminal offense, give rise to civil liability or otherwise violate any applicable law.</p>
          <p>Clickrwanda may promote, endorse and/or contain links to third party sites and/or services and/or goods. These sites, services and and/or goods are beyond the control of clickrwanda. Clickrwanda is not involved in transactions between users and the operators of such third-party sites. Clickrwanda does not accept responsibility for their content, services and/or products.</p>
          <p>We cannot guarantee continuous, error-free or secure access to our services or that defects in the service will be corrected.</p>
          <p>While we will use reasonable efforts to maintain an uninterrupted service, we cannot guarantee this and we do not give any promises or warranties (whether express or implied) about the availability of our services.</p>
          <p>Accordingly, to the extent legally permitted we expressly disclaim all warranties, representations and conditions, express or implied, including those of quality, merchantability, merchantable quality, durability, fitness for a particular purpose and those arising by statute. We are not liable for any loss, whether of money (including profit), goodwill, or reputation, or any special, indirect, or consequential damages arising out of your use of clickrwanda, even if you advise us or we could reasonably foresee the possibility of any such damage occurring. Some jurisdictions do not allow the disclaimer of warranties or exclusion of damages, so such disclaimers and exclusions may not apply </p>
          <p>Nothing in these terms shall limit our liability for fraudulent misrepresentation, for death or personal injury resulting from our negligence or the negligence of our agents or employees.</p>
          <h4>Personal Information</h4>
          <p>By using Clickrwanda, you agree that you are giving your voluntary and informed consent to the collection, transfer, storage and use of your personal information by Clickrwanda on servers located in different locations, and for such purposes, as further described in our Privacy Policy. You also agree that you are giving your voluntary and informed consent to receive marketing communications from us unless you tell us that you prefer not to receive such communications.</p>
          <h4>Security</h4>
          <p>In order to ensure the security and reliable operation of the site for all users, Clickrwanda reserves the right at its discretion to take whatever action it finds necessary to preserve the security, integrity and reliability of its network and back-office applications. Any user who commits any of the offences shall, notwithstanding criminal prosecution, be liable for all resulting liability, loss or damages suffered and/or incurred by Clickrwanda and its affiliates, agents and/or partners.</p>
          <h4>Resolution of disputes</h4>
          <p>If a dispute arises between you and Clickrwanda, we strongly encourage you to first contact us directly to seek a resolution. We will consider reasonable requests to resolve the dispute through alternative dispute resolution procedures, such as mediation or arbitration, as alternatives to litigation.</p>
          <h3>General</h3>
          <p>These terms and the other policies posted on Clickrwanda constitute the entire agreement between Clickrwanda and you, superseding any prior agreements.</p>
          <p>To the extent permitted by applicable law, this Agreement shall be governed and construed in all respects by the laws of Rwanda. You agree that any claim or dispute you may have against Clickrwanda must be resolved by the courts of Rwanda, and you and Clickrwanda both agree to submit to the non-exclusive jurisdiction of Kigali, Rwanda.</p>
          <p>If we don&apos;t enforce any particular provision, we are not waiving our right to do so later. If a court strikes down any of these terms, the remaining terms will survive. We may automatically assign this agreement in our sole discretion in accordance with the notice provision below.</p>
          <p>Except for notices relating to illegal or infringing content, your notices to us must be sent by registered mail to Huza 250 Ltd, 4th Floor Prince House,Kigali, Rwanda. We will send notices to you via the email address you provide, or by registered mail. Notices sent by registered mail will be deemed received five days following the date of mailing.</p>
          <p>We may update this agreement at any time, with changes taking effect when you next post or 30 days after we post the updated policy on the site, whichever is sooner. No other amendment to this agreement will be effective unless made in writing, signed by users and by us.</p>

     </div>
     )
}

export default TermsPage;