export const LoadingAd = () => {
     return(
          <div className="loading-comp loading-ad"></div>
     )
}

export const LoadingImage = () => {
     return(
          <div className="loading-comp loading-image"></div>
     )
}

export const LoadingSeller = () => {
     return (
          <div className="loading-comp loading-seller"></div>
     )
}

export const LoadingCat = () => {
     return(
          <div className="loading-comp loading-cat"></div>
     )
}