import { DashboardContainer, DashboardRow } from "./components/DashboardComponents"

const ShopStatistics = () => {
     return (
          <DashboardContainer>
               <DashboardRow><h2>Shop Visits </h2></DashboardRow>

          </DashboardContainer>
     )
}

export default ShopStatistics