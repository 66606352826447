
export const s3Folders = {
     adverts: 'adverts',
     banners: 'banners',
     categories: 'categories',
     logos: 'logos',
     payPlans: 'pay-plans',
     quotations: 'quotations',
     temp: "temp",
     userCVs: "userCVs"
};


