
const About = () => {
  return (
    <div className="static-page">
      <h2>About Click Rwanda</h2>
      <p>Clickrwanda is a Rwandan online marketplace and classified advertising company, based in Kigali, Rwanda. </p>
      <p>Clickrwanda has listings in over 100 cities in Rwanda and in categories such as mobile phones, Vehicles, Properties, jobs, services and education. It provides buyers and sellers with an avenue to meet and exchange goods and services.</p>
    </div>
  )
}

export default About